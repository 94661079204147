<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="850"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        Asignar códigos
      </v-btn>
    </template>
    <v-form>
      <v-card>
        <!-- begin::Modal title -->
        <v-toolbar dark color="primary" class="elevation-0 px-3">
          <span class="headline">Asignar código</span>
        </v-toolbar>
        <!-- end::Modal title -->

        <!-- begin::modal content and fields -->
        <v-card-text>
          <v-container>
            <v-row class="d-flex justify-center">
              <v-col cols="12" class="pb-0 mb-0">
                <form-group
                  name="estudiantes"
                  :validator="$v.assignCode.students"
                >
                  <v-autocomplete
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    v-model="assignCode.students"
                    :filter="customFilter"
                    :items="data"
                    filled
                    chips
                    rounded
                    color="blue-grey lighten-2"
                    label="Estudiantes..."
                    item-text="full_name"
                    item-value="uuid"
                    multiple
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)"
                      >
                        <v-avatar left>
                          <v-img :src="data.item.photo"></v-img>
                        </v-avatar>
                        <span class="font-weight-bold mr-1"
                          >{{ data.item.code }}
                        </span>
                        -
                        {{ data.item.first_name.split(" ")[0] }}
                        {{ data.item.last_name.split(" ")[0] }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-avatar>
                        <img :src="data.item.photo" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="font-weight-bold">
                            {{ data.item.code }}
                          </span>
                          -
                          <span class="text-uppercase font-weight-medium">
                            {{ data.item.full_name }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  rounded
                  filled
                  hide-details
                  prepend-icon=""
                  dense
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Buscar código"
                  v-model="searchCode"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="códigos" :validator="$v.assignCode.code">
                  <v-card class="elevation-0 py-0 pl-0 pr-0 mt-2 rounded-lg">
                    <v-divider class="ma-0"></v-divider>
                    <v-list-item-group
                      v-model="assignCode.code"
                      color="primary"
                      active-class="green--text"
                      style="scrollbar-width: thin !important; scrollbar-color: #888 #f1f1f1 !important; max-height: 25vh; overflow-y: auto;"
                    >
                      <v-list class="v-list py-0">
                        <v-list-item
                          class="mx-0 mr-md-2 pr-2 pr-md-2 pl-1 pl-md-2"
                          v-for="(item, index) in filteredCodes"
                          :key="index"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-content class="pb-0">
                              <v-list-item-subtitle class="mb-1">
                                <v-chip
                                  :color="
                                    `${
                                      setCodeTypeTheme(item.CodeType?.name)
                                        .color
                                    } lighten-4 font-weight-medium`
                                  "
                                  class="mt-1 ml-2"
                                >
                                  <p
                                    :class="
                                      `font-weight-medium ${
                                        setCodeTypeTheme(item.CodeType?.name)
                                          .color
                                      }--text mb-0`
                                    "
                                  >
                                    Código
                                    <span
                                      :class="
                                        `text-lowercase font-weight-bold ${
                                          setCodeTypeTheme(item.CodeType?.name)
                                            .color
                                        }--text`
                                      "
                                      >{{ item.CodeType?.name }}</span
                                    >
                                  </p>
                                </v-chip>
                              </v-list-item-subtitle>

                              <p class="font-weight-bold my-1 ml-3 pr-2">
                                {{ item.name }}
                              </p>

                              <p
                                class="font-weight-medium text--secondary ml-3 pr-3"
                              >
                                {{ item.description }}
                              </p>
                            </v-list-item-content>
                            <v-list-item-action-text>
                              <v-icon
                                v-if="active"
                                color="green darken-3"
                                class="mr-5"
                              >
                                mdi-check-circle-outline
                              </v-icon>
                            </v-list-item-action-text>
                          </template>
                        </v-list-item>
                      </v-list>
                    </v-list-item-group>
                    <v-divider class="ma-0"></v-divider>
                  </v-card>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group
                  name="Observación"
                  :validator="$v.assignCode.observation"
                >
                  <v-textarea
                    counter
                    filled
                    clearable
                    rounded
                    auto-grow
                    rows="2"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Observación (opcional)"
                    placeholder="Ej.: El estudiante..."
                    v-model.trim="assignCode.observation"
                    @blur="$v.assignCode.observation.$touch()"
                    @paste="handlePaste($event)"
                  ></v-textarea>
                </form-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <!-- end:: modal content and fields -->

        <!-- begin::actions -->
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn
            color="primary"
            depressed
            @click="submit"
            :loading="isLoading"
            :disabled="disabledButton"
            >Asignar</v-btn
          >
        </v-card-actions>
        <!-- end::actions -->
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import codesRepository from "../../../repositories/codeRepository";
import codePlaceRepository from "../../../repositories/codePlaceRepository";
import { mapGetters } from "vuex";

export default {
  name: "AttendanceAssignCodeModal",
  props: {
    data: {
      type: Array,
    },
    schedule: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      codes: {
        data: [],
        isLoading: false,
      },
      selectedCode: {},
      CODE_PLACE: "Aula",
      codePlaces: [],
      assignCode: {
        code: null,
        students: [],
        observation: "",
      },
      searchCode: "",
    };
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    filteredCodes() {
      const searchText = this.searchCode.toLowerCase();
      return this.codes.data.filter((item) =>
        item.name.toLowerCase().includes(searchText)
      );
    },
    disabledButton() {
      if (
        this.assignCode.code == null ||
        this.assignCode.students.length === 0
      ) {
        return true;
      }
    },
  },
  methods: {
    loadCodes() {
      this.codes.isLoading = true;
      codesRepository
        .getAllCodes()
        .then(({ data }) => {
          this.codes.data = data.data;
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: error.message,
          });
        })
        .finally(() => {
          this.codes.isLoading = false;
        });
    },
    loadCodePlace() {
      codePlaceRepository
        .getAllCodePlaces()
        .then(({ data }) => {
          this.codePlaces = data.data;
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: error.message,
          });
        });
    },
    //Function that returns color name from code name
    calculateChipColor(name) {
      if (name === "Leve") {
        return "yellow darken-1";
      } else if (name === "Grave") {
        return "orange darken-1";
      } else if (name === "Muy grave") {
        return "red darken-1";
      } else if (name == "positivos") {
        return "green darken-1";
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.formattedData();
    },
    // Format data to send to api
    formattedData() {
      const code_place = this.codePlaces.find(
        (element) => element.name === this.CODE_PLACE
      );
      let formattedData = {
        selected_students: this.assignCode.students,
        schedule_id: !!this.schedule.id ? this.schedule.id : null,
        code_id: this.selectedCode.id,
        observation: this.assignCode.observation,
        id_user: this.currentUserPersonalInfo.id_user,
        code_place_id: code_place.id,
      };
      this.storeAssignCode(formattedData);
    },
    // Store function
    storeAssignCode(data) {
      this.isLoading = true;
      codesRepository
        .assignCodeToStudent(data)
        .then(({ data }) => {
          this.sweetAlertResponse(data);
          this.closeModal();
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: error.message,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // Function that remove item from the autocomplete
    remove(item) {
      const index = this.assignCode.students.indexOf(item.uuid);
      if (index >= 0) this.assignCode.students.splice(index, 1);
    },
    // Set a color from code type name
    setCodeTypeTheme(codeTypeName) {
      let codeTypeSchemas = {
        Positivo: {
          color: "green",
          icon: "star",
        },
        Leve: {
          color: "orange",
          icon: "star-minus",
        },
        Grave: {
          color: "red",
          icon: "star-minus",
        },
        "Muy grave": {
          color: "purple",
          icon: "star-minus",
        },
      };

      let defaultSchema = {
        color: "grey",
        icon: "star-minus",
      };

      if (codeTypeSchemas.hasOwnProperty(codeTypeName)) {
        return codeTypeSchemas[codeTypeName];
      } else {
        return defaultSchema;
      }
    },
    customFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      const codeMatch = item.code.toString().includes(searchText);
      const fullNameMatch = item.full_name.toLowerCase().includes(searchText);
      return codeMatch || fullNameMatch;
    },
    handlePaste(event) {
      event.preventDefault();
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      paste = paste.replace(/ {3,}/g, "  ");
      this.assignCode.observation = this.assignCode.observation + paste;
    },
    toggleModal() {
      this.dialog = !this.dialog;
    },
    clear() {
      this.assignCode.code = null;
      this.assignCode.observation = "";
      this.assignCode.students = [];
      this.selectedCode = {};
      this.searchCode = "";
    },
    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.clear();
    },
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.loadCodes();
        this.loadCodePlace();
      }
    },
    "assignCode.code"(newValue, oldValue) {
      if (newValue != undefined && newValue != oldValue) {
        this.selectedCode = this.filteredCodes[newValue];
      }
    },
  },
  validations: {
    assignCode: {
      code: {
        required,
      },
      students: {
        required,
      },
      observation: {},
    },
  },
};
</script>
