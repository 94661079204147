import { PedagogicalDiaryService } from "@/core/services/apis.service";

const RESOURCE = "class-times"

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllClassTimes = () => {
  return PedagogicalDiaryService.get(`${RESOURCE}`);
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getActualDate = () => {
  return PedagogicalDiaryService.get(`${RESOURCE}/actual-date`);
};


/**
 * Get request to fetch early class time time
 * @returns {*}
 */
export const getMinAndMaxClassTime = () => {
  return PedagogicalDiaryService.get(`${RESOURCE}/minAndMaxClassTime`);
}

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getClassTime = (id) => {
  return PedagogicalDiaryService.get(`${RESOURCE}`, id);
};

/**
 * POST request to create a new class time
 * @param payload
 * @returns {*}
 */
export const createClassTime = (payload) => {
  return PedagogicalDiaryService.post(`${RESOURCE}`, payload);
};

/**
 * PUT request to update a specific class time
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateClassTime = (id, payload) => {
  return PedagogicalDiaryService.update(`${RESOURCE}`, id, payload);
};

/**
 * DELETE request to delete the specified class time
 * @param id
 * @returns {*}
 */
export const deleteClassTime = (id) => {
  return PedagogicalDiaryService.delete(`${RESOURCE}/${id}`);
};

export default {
  getAllClassTimes,
  getMinAndMaxClassTime,
  getClassTime,
  createClassTime,
  updateClassTime,
  deleteClassTime,
  getActualDate
};
