<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <v-row>
          <v-col cols="12" md="6" lg="8">
            <div class="card-title">
              <h3 class="card-label">
                Listado para registro de asistencia
                <span class="d-block text-muted pt-2 font-size-sm"
                  >Puede seleccionar si el estudiante está presente o ausente y
                  registrar si llegó tarde.</span
                >
              </h3>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="card-body">
        <v-row v-if="notStudentsAssignedAtThisTime" class="mt-5">
          <v-col class="mx-auto d-flex flex-column" cols="12" sm="10" md="8">
            <div class="mx-auto mb-4 pa-8 rounded-circle indigo lighten-4">
              <v-icon x-large color="indigo"
                >mdi-account-multiple-remove</v-icon
              >
            </div>
            <p class="ma-0 text-h5 font-weight-bold text-center">
              {{ currentTime }}
            </p>
            <p
              class="text-body-1 blue-grey--text text--lighten-1 ma-0 mb-4 text-center"
            >
              {{ assistanceDate }}
            </p>
            <p class="text-center font-weight-medium text-h6 ma-2 mb-4">
              No tiene grupo asignado a esta hora.
            </p>
            <v-alert
              prominent
              dense
              outlined
              type="info"
              color="blue darken-1"
              class="ma-2 text-body-1 font-weight-medium text-xs-justify text-md-center rounded-lg"
            >
              Sí tiene horarios asignados en la hora actual
              <strong>ACTUALICE</strong> la página para cargarlos.
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-else-if="errorBanner" class="mt-5">
          <v-col class="mx-auto d-flex flex-column" cols="12" sm="10" md="8">
            <div class="mx-auto mb-4 pa-8 rounded-circle red lighten-4">
              <v-icon x-large color="red">mdi-alert-circle-outline</v-icon>
            </div>
            <p class="ma-0 text-h5 font-weight-bold text-center">
              {{ currentTime }}
            </p>
            <p
              class="text-body-1 blue-grey--text text--lighten-1 ma-0 mb-4 text-center"
            >
              {{ assistanceDate }}
            </p>
            <p class="text-center font-weight-medium text-h6 ma-2 mb-4">
              Lo sentimos.
            </p>
            <v-alert
              prominent
              dense
              outlined
              type="info"
              color="red darken-1"
              class="ma-2 text-body-1 font-weight-medium text-xs-justify text-md-center rounded-lg"
            >
              Hubo un error al intentar obtener los datos.
            </v-alert>
          </v-col>
        </v-row>
        <v-card class="elevation-0" v-else>
          <div
            v-if="studentClass.isLoading"
            style="height: 400px"
            class="white mt-3 mb-6"
          >
            <v-row class="fill-height" align-content="center" justify="center">
              <v-col class="text-subtitle-1 text-center" cols="12">
                Cargando listado de asistencia...
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                  color="blue darken-1"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row>
              <!-- Date and Time -->
              <v-col cols="12" sm="6" md="6" lg="4" xl="4">
                <div
                  class="d-flex flex-column justify-start align-center flex-md-row justify-md-start align-md-center py-5 my-5"
                >
                  <v-avatar color="light-green lighten-4" size="68">
                    <v-icon dark color="light-green">
                      mdi-calendar-clock-outline
                    </v-icon>
                  </v-avatar>
                  <div
                    class="d-flex flex-column justify-md-center align-md-start pt-2 pt-md-0 pl-md-3"
                  >
                    <div
                      class="text-caption blue-grey--text text--darken-3 text-center text-md-left"
                    >
                      Horario
                    </div>
                    <div
                      class="text-subtitle-1 font-weight-bold text-center text-md-left"
                    >
                      {{ startTime }} - {{ endTime }}
                    </div>
                    <div
                      class="text-caption blue-grey--text text--darken-3 text-center text-md-left"
                    >
                      {{ assistanceDate }}
                    </div>
                  </div>
                </div>
              </v-col>
              <!-- Countdown -->
              <v-col cols="12" sm="6" md="6" lg="4" xl="4">
                <div
                  class="d-flex flex-column justify-start align-center flex-md-row justify-md-start align-md-center py-5 my-5"
                >
                  <v-avatar color="red lighten-4" size="68">
                    <v-icon dark color="red"> mdi-alarm </v-icon>
                  </v-avatar>
                  <div
                    class="d-flex flex-column justify-md-center align-md-start pt-2 pt-md-0 pl-md-3"
                  >
                    <div
                      class="text-caption blue-grey--text text--darken-3 text-center text-md-left"
                    >
                      Tiempo restante para pasar asistencia
                    </div>
                    <div
                      class="text-subtitle-1 font-weight-bold text-center text-md-left"
                    >
                      {{ hours }}:{{ minutes }}:{{ seconds }}
                    </div>
                  </div>
                </div>
              </v-col>
              <!-- Student count  -->
              <v-col cols="12" md="6" lg="4" xl="4">
                <div
                  class="d-flex flex-column justify-start align-center flex-md-row justify-md-start align-md-center py-5 my-5"
                >
                  <v-avatar color="light-blue lighten-4" size="68">
                    <v-icon dark color="light-blue">
                      mdi-account-group-outline
                    </v-icon>
                  </v-avatar>
                  <div
                    class="d-flex flex-column justify-md-center align-md-start pt-2 pt-md-0 pl-md-3"
                  >
                    <div
                      class="text-subtitle-1 font-weight-bold text-center text-md-left"
                    >
                      {{ totalStudents }} estudiantes
                    </div>
                    <div
                      class="text-caption blue-grey--text text--darken-3 text-center text-md-left"
                    >
                      en la clase
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <div v-for="(list, index) in studentClass.data" :key="index">
              <AttendanceListView :listAttendance="list"></AttendanceListView>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <!-- end::Card -->
  </div>
</template>

<script>
import studentClassRepository from "../../../repositories/studentClassRepository";
import AttendanceListView from "@/components/elements/student_class/AttendanceListView.vue";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "studentClass",
  title: "Asistencia | GE ITR",
  components: { AttendanceListView },
  created() {
    this.updateTime();
    this.timer = setInterval(this.updateTime, 1000);
  },
  mounted() {
    this.studentClass.isLoading = true;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Asistencia", route: "student_class" },
    ]);
    /*Acá se hace referencia al permiso de las acciones - Despues se creará el de zona*/
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Asistencia");

    window.addEventListener("focus", this.handleFocus);
  },
  data() {
    return {
      //used to load the students of a teacher by the hour
      timeOfAccess: null,
      today: null,
      hours: null,
      minutes: null,
      seconds: null,
      timerInterval: null,
      //used for the timer
      currentTime: null,
      startTime: null,
      endTime: null,
      timer: null,
      // DailyBlock
      schedule: [],
      // Error variable
      errorBanner: false,
      // Table and student list
      studentClass: {
        showAlert: false,
        data: [],
        isLoading: false,
      },
      totalStudents: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  methods: {
    showMdlAttendanceAssignCodeModal() {
      this.$refs.mdlAttendanceAssignCode.toggleModal();
    },
    getLocalTime() {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      this.today = String(now.getDay());
      return `${hours}:${minutes}:${seconds}`;
    },
    updateTime() {
      this.currentTime = this.getLocalTime();
    },
    async loadAttendanceList() {
      try {
        this.studentClass.isLoading = true;
        const { data } = await studentClassRepository.getAttendanceList(
          this.currentUserPersonalInfo.id_user
        );

        if (data.length > 0) {
          this.studentClass.data = data;
          this.schedule = this.studentClass.data.map((obj) => obj.schedule);
          this.totalStudents = this.studentClass.data.reduce(
            (total, obj) => total + obj.students.length,
            0
          );

          if (this.totalStudents > 0) {
            this.setTimeRange();
          } else {
            this.errorBanner = true;
          }
        } else {
          this.studentClass.showAlert = true;
        }
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : "Error al cargar la lista de asistencias";
        this.fireToast({
          icon: "error",
          title: errorMessage,
        });
        console.error(error);
        this.errorBanner = true;
      } finally {
        this.studentClass.isLoading = false;
      }
    },
    setTimeRange() {
      const getLowerRank = this.schedule.reduce((min, current) => {
        const minStartTime = min.start_time
          .split(":")
          .reduce((acc, timePart) => acc * 60 + +timePart, 0);
        const currentStartTime = current.start_time
          .split(":")
          .reduce((acc, timePart) => acc * 60 + +timePart, 0);
        const minEndTime = min.end_time
          .split(":")
          .reduce((acc, timePart) => acc * 60 + +timePart, 0);
        const currentEndTime = current.end_time
          .split(":")
          .reduce((acc, timePart) => acc * 60 + +timePart, 0);

        const minRange = minEndTime - minStartTime;
        const currentRange = currentEndTime - currentStartTime;

        return minRange < currentRange ? min : current;
      });
      this.startTime = getLowerRank.start_time;
      this.endTime = getLowerRank.end_time;
      this.limitAttendance();
    },
    clear() {
      this.schedule = [];
      this.startTime = "";
      this.endTime = "";
      this.studentClass.data = [];
      this.totalStudents;
    },
    limitAttendance() {
      if (!this.notStudentsAssignedAtThisTime) {
        const partesHora = !!this.endTime ? this.endTime.split(":") : null;
        const horaCierre = new Date();
        horaCierre.setHours(
          Number(partesHora[0]),
          Number(partesHora[1]),
          Number(partesHora[2])
        );
        const actualPartes = this.currentTime
          ? this.currentTime.split(":")
          : null;
        const actual = new Date();
        actual.setHours(
          Number(actualPartes[0]),
          Number(actualPartes[1]),
          Number(actualPartes[2])
        );
        const tiempoRestante = horaCierre.getTime() - actual.getTime();
        if (tiempoRestante > 0) {
          let totalSeconds = tiempoRestante / 1000;
          this.startTimer(totalSeconds);
          setTimeout(() => {
            this.clear();
            this.loadAttendanceList();
          }, tiempoRestante);
        } else {
          this.loadAttendanceList();
        }
      }
    },
    startTimer(totalSeconds) {
      this.timerInterval = setInterval(() => {
        if (totalSeconds > 0) {
          const hours = Math.floor(totalSeconds / 3600);
          const minutes = Math.floor((totalSeconds % 3600) / 60);
          const seconds = totalSeconds % 60;

          this.hours = hours.toString().padStart(2, "0");
          this.minutes = minutes.toString().padStart(2, "0");
          this.seconds = seconds
            .toFixed(0)
            .toString()
            .padStart(2, "0");

          totalSeconds--;
        } else {
          this.stopTimer();
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timerInterval);
    },
    handleFocus() {
      this.stopTimer();
      this.limitAttendance();
    },
  },
  computed: {
    ...mapGetters(["currentPageActions", "currentUserPersonalInfo"]),
    assistanceDate() {
      const myDate = new Date(this.date + "T24:00:00.000Z"); // Add "T00:00:00.000Z" to the date string
      const formatter = new Intl.DateTimeFormat("es-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
      });
      const fullDate = formatter.format(myDate);
      return fullDate;
    },
    notStudentsAssignedAtThisTime() {
      // Se establece en true cuando cuando no tiene grupo asignado a esa hora ó no tiene estudiantes.
      return this.studentClass.showAlert;
    },
  },
  watch: {
    currentUserPersonalInfo(newV, oldV) {
      if (newV != oldV && !!oldV) {
        this.loadAttendanceList();
      }
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
    this.stopTimer();
    clearInterval(this.timer);
  },
  destroyed() {
    window.removeEventListener("focus", this.handleFocus);
  },
};
</script>
