<template>
  <v-container>
    <v-row>
      <v-col cols="10">
        <v-card class="rounded-lg elevation-0">
          <v-chip text class="mb-2" color="success" outlined>
            <v-icon left> mdi-store </v-icon>
            <p class="text-md-body-1 font-weight-bold mb-0">
              {{ localName }}
            </p>
          </v-chip>
          <p class="text-h4 font-weight-medium mb-0">
            {{ subjectName }}
          </p>
          <p class="text-h6 font-weight-bold mb-0">
            {{ subjectGrade }} {{ subjectSpeciality }}
            <span v-if="studentSectionGroup != ''">-</span>
            {{ studentSectionGroup }}
          </p>
        </v-card>
      </v-col>
      <v-col cols="2" class="d-flex justify-center align-center">
        <!-- BEGIN::Modal to assign code to student -->
        <AttendanceAssignCodeModalVue
          ref="mdlAttendanceAssignCode"
          :data="listAttendance.students"
          :schedule="listAttendance.schedule"
        />
        <!-- END::Modal to assign code to student -->
      </v-col>
    </v-row>
    <!-- begin::Search Form -->
    <div class="mb-5">
      <v-row class="align-items-center">
        <v-col cols="12" class="my-2 my-md-0">
          <!-- search for a student  -->
          <v-text-field
            filled
            rounded
            hide-details
            clearable
            prepend-icon=""
            prepend-inner-icon="mdi-magnify"
            placeholder="Buscar estudiante en lista..."
            v-model="listSearch"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <!-- end::Search Form -->
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="filteredHeaders"
          :items="listAttendance.students"
          :search="listSearch"
          :items-per-page="100"
          hide-default-footer
          show-group-by
          sort-by="last_name"
          :group-by="tableManage"
          class="flex-table"
          fixed-header
        >
          <template
            v-if="listAttendance.isTechnical"
            v-slot:[`group.header`]="{
              group,
              headers,
              toggle,
              isOpen,
            }"
          >
            <td :colspan="headers.length">
              <v-btn
                @click="toggle"
                small
                icon
                :ref="group"
                :data-open="isOpen"
              >
                <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-btn>
              <v-icon dense dark left color="blue">mdi-account-group</v-icon>
              <span
                class="blue-grey--text text--darken-3 font-weight-bold text-subtitle-1"
                >{{ group }}</span
              >
            </td>
          </template>
          <template
            v-else
            v-slot:[`group.header`]="{
              group,
              headers,
              toggle,
              isOpen,
            }"
          >
            <td :colspan="headers.length">
              <v-btn
                @click="toggle"
                small
                icon
                :ref="group"
                :data-open="isOpen"
              >
                <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-btn>
              <v-icon dense dark left color="blue">mdi-account-group</v-icon>
              <span
                class="blue-grey--text text--darken-3 font-weight-bold text-subtitle-1"
                >{{ group }}</span
              >
            </td>
          </template>
          <template v-slot:[`item.photo`]="{ item }">
            <v-avatar size="70" class="my-3">
              <v-img :src="item.photo"></v-img>
            </v-avatar>
          </template>
          <template v-slot:[`item.code`]="{ item }">
            <p class="text-body-1 mb-0 font-weight-bold">
              {{ item.code }}
            </p>
          </template>
          <template v-slot:[`item.full_name`]="{ item }">
            <p class="text-body-2 mb-0 font-weight-medium">
              {{ item.full_name }}
            </p>
          </template>
          <template v-slot:[`item.present`]="{ item }">
            <v-tooltip bottom nudge-top="30" nudge-left="45" color="success">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-checkbox
                    :disabled="
                      item.attendance_status_id == 1 || item.is_justified
                    "
                    v-model="item.attendance_status_id"
                    :value="1"
                    @change="handleCheckboxChange(item)"
                  ></v-checkbox>
                </span>
              </template>
              <span>Está presente</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.late`]="{ item }">
            <v-tooltip bottom nudge-top="30" nudge-left="40" color="warning">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-checkbox
                    :disabled="
                      item.attendance_status_id == 3 || item.is_justified
                    "
                    v-model="item.attendance_status_id"
                    :value="3"
                    @change="handleCheckboxChange(item)"
                  ></v-checkbox>
                </span>
              </template>
              <span>Llegó tarde </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.absent`]="{ item }">
            <v-tooltip bottom nudge-top="30" nudge-left="25" color="red">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-checkbox
                    :disabled="
                      item.attendance_status_id == 2 || item.is_justified
                    "
                    v-model="item.attendance_status_id"
                    :value="2"
                    @change="handleCheckboxChange(item)"
                  ></v-checkbox>
                </span>
              </template>
              <span>Ausente </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.is_justified`]="{ item }">
            <v-chip
              v-if="item.is_justified"
              class="ma-2 text-caption"
              color="green lighten-1"
              text-color="white"
            >
              <v-icon left> mdi-check-circle </v-icon>
              Permiso
            </v-chip>
          </template>
        </v-data-table></v-col
      >
    </v-row>
  </v-container>
</template>
<script>
import studentClassRepository from "../../../repositories/studentClassRepository";
import classTimeRepository from "../../../repositories/classTimeRepository";
import AttendanceAssignCodeModalVue from "@/components/modals/codes/AttendanceAssignCodeModal.vue";
import { mapGetters } from "vuex";
export default {
  name: "AttendanceListView",
  components: {
    AttendanceAssignCodeModalVue,
  },
  props: {
    listAttendance: {
      type: Object,
    },
  },
  data() {
    return {
      listSearch: "",
      date: "",
    };
  },
  mounted() {
    this.getDateTime();
  },
  methods: {
    getDateTime() {
      classTimeRepository
        .getActualDate()
        .then((data) => {
          let formattedDate = data.data.date;
          this.date = formattedDate;
        })
        .catch((error) => {
          console.log(error);
          this.fireToast({
            icon: "error",
            title: "Hubo un error al obtener la fecha y hora del servidor",
          });
        });
    },
    handleCheckboxChange(item) {
      let formattedData = {
        student_class_id: item.student_class_id,
        schedule_id: this.listAttendance.schedule.schedule_id,
        attendance_status_id: item.attendance_status_id,
        date: this.date,
        student_id: item.uuid,
        user_id: this.currentUserPersonalInfo.id_user,
      };

      this.storeStudentClass(formattedData);
    },
    storeStudentClass(attendance) {
      studentClassRepository
        .createStudentClass(attendance)
        .then((data) => {
          this.setLog(attendance, data.data.id);
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: "Error al guardar los datos",
          });
        });
    },
    setLog(attendance, id) {
      const log = {
        user_id: this.currentUserPersonalInfo.id_user,
        action: "UPDATE",
        description: `Paso asistencia al alumno con código ${attendance.student_id}`,
        table_groupable_type: "StudentClass",
        table_groupable_uuid: id,
      };
      this.addLog(log);
    },
  },
  computed: {
    ...mapGetters(["currentPageActions", "currentUserPersonalInfo"]),
    subjectName() {
      return this.listAttendance.subject.name;
    },
    localName() {
      return this.listAttendance.schedule.local;
    },
    subjectGrade() {
      return this.listAttendance.students[0]?.grade;
    },
    subjectSpeciality() {
      if (
        this.listAttendance.isTechnical ||
        this.listAttendance.schedule.section_id === 6
      ) {
        return this.listAttendance.students[0]?.technical_group;
      }
      return "";
    },
    studentSectionGroup() {
      if (this.listAttendance.isTechnical) {
        return this.listAttendance.students[0]?.section_group.substring(0, 1);
      } else if (this.listAttendance.schedule.section_id === 6) {
        return "";
      }
      return this.listAttendance.students[0]?.section_group;
    },
    tableManage() {
      if (
        this.listAttendance.isTechnical ||
        this.listAttendance.schedule.section_id === 6
      ) {
        return "section_group";
      } else {
        return "technical_group";
      }
    },
    filteredHeaders() {
      if (
        this.listAttendance.isTechnical ||
        this.listAttendance.schedule.section_id === 6
      ) {
        return [
          { text: "Foto", value: "photo", groupable: false, sortable: false },
          { text: "Código", value: "code", groupable: false, sortable: false },
          {
            text: "Nombre completo",
            value: "full_name",
            groupable: false,
            sortable: false,
          },
          { text: "Sección", value: "section_group" },
          {
            text: "Está presente",
            value: "present",
            groupable: false,
            sortable: false,
          },
          {
            text: "Llegó tarde",
            value: "late",
            groupable: false,
            sortable: false,
          },
          {
            text: "Ausente",
            value: "absent",
            groupable: false,
            sortable: false,
          },
          {
            text: "",
            value: "is_justified",
            groupable: false,
            sortable: false,
          },
          { text: "", value: "actions", groupable: false, sortable: false },
        ];
      } else {
        return [
          { text: "Foto", value: "photo", groupable: false, sortable: false },
          { text: "Código", value: "code", groupable: false, sortable: false },
          {
            text: "Nombre completo",
            value: "full_name",
            groupable: false,
            sortable: false,
          },
          { text: "Especialidad", value: "technical_group" },
          {
            text: "Está presente",
            value: "present",
            groupable: false,
            sortable: false,
          },
          {
            text: "Llegó tarde",
            value: "late",
            groupable: false,
            sortable: false,
          },
          {
            text: "Ausente",
            value: "absent",
            groupable: false,
            sortable: false,
          },
          {
            text: "",
            value: "is_justified",
            groupable: false,
            sortable: false,
          },
          { text: "", value: "actions", groupable: false, sortable: false },
        ];
      }
    },
  },
};
</script>
